import {
  SET_CACHE,
  SET_BIO,
  SET_CONTACT,
  SET_EMPLOYMENT,
} from '../constants/ActionTypes';
// import data from 'app/routes/contact/data/contactList'

const INIT_STATE = {
  bio: {},
  contact: {
    addresses: [],
    phoneNumbers: [],
    emailAddresses: []
  },
  employment: {
    history: []
  },
  cache: {
    recentUpdates: [],
    recentComments: [],
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CACHE: {
      return {
        ...state,
        cache: {...state.cache, ...action.payload}
      };
    }
    case SET_BIO: {
      return {
        ...state,
        bio: {...state.bio, ...action.payload}
      };
    }
    case SET_CONTACT: {
      return {
        ...state,
        contact: {...state.contact, ...action.payload}
      };
    }
    case SET_EMPLOYMENT: {
      return {
        ...state,
        employment: {...state.employment, ...action.payload}
      };
    }
    default:
      return state;
  }
}
