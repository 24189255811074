
const Lists = {

    countries: [
            {
                "label": "Afghanistan",
                "value": "af"
            },
            {
                "label": "Albania",
                "value": "al"
            },
            {
                "label": "Algeria",
                "value": "dz"
            },
            {
                "label": "American Samoa",
                "value": "as"
            },
            {
                "label": "Andorra",
                "value": "ad"
            },
            {
                "label": "Angola",
                "value": "ao"
            },
            {
                "label": "Anguilla",
                "value": "ai"
            },
            {
                "label": "Antarctica",
                "value": "aq"
            },
            {
                "label": "Antigua and Barbuda",
                "value": "ag"
            },
            {
                "label": "Argentina",
                "value": "ar"
            },
            {
                "label": "Armenia",
                "value": "am"
            },
            {
                "label": "Aruba",
                "value": "aw"
            },
            {
                "label": "Australia",
                "value": "au"
            },
            {
                "label": "Austria",
                "value": "at"
            },
            {
                "label": "Azerbaijan",
                "value": "az"
            },
            {
                "label": "Bahamas (the)",
                "value": "bs"
            },
            {
                "label": "Bahrain",
                "value": "bh"
            },
            {
                "label": "Bangladesh",
                "value": "bd"
            },
            {
                "label": "Barbados",
                "value": "bb"
            },
            {
                "label": "Belarus",
                "value": "by"
            },
            {
                "label": "Belgium",
                "value": "be"
            },
            {
                "label": "Belize",
                "value": "bz"
            },
            {
                "label": "Benin",
                "value": "bj"
            },
            {
                "label": "Bermuda",
                "value": "bm"
            },
            {
                "label": "Bhutan",
                "value": "bt"
            },
            {
                "label": "Bolivia (Plurinational State of)",
                "value": "bo"
            },
            {
                "label": "Bonaire, Sint Eustatius and Saba",
                "value": "bq"
            },
            {
                "label": "Bosnia and Herzegovina",
                "value": "ba"
            },
            {
                "label": "Botswana",
                "value": "bw"
            },
            {
                "label": "Bouvet Island",
                "value": "bv"
            },
            {
                "label": "Brazil",
                "value": "br"
            },
            {
                "label": "British Indian Ocean Territory (the)",
                "value": "io"
            },
            {
                "label": "Brunei Darussalam",
                "value": "bn"
            },
            {
                "label": "Bulgaria",
                "value": "bg"
            },
            {
                "label": "Burkina Faso",
                "value": "bf"
            },
            {
                "label": "Burundi",
                "value": "bi"
            },
            {
                "label": "Cabo Verde",
                "value": "cv"
            },
            {
                "label": "Cambodia",
                "value": "kh"
            },
            {
                "label": "Cameroon",
                "value": "cm"
            },
            {
                "label": "Canada",
                "value": "ca"
            },
            {
                "label": "Cayman Islands (the)",
                "value": "ky"
            },
            {
                "label": "Central African Republic (the)",
                "value": "cf"
            },
            {
                "label": "Chad",
                "value": "td"
            },
            {
                "label": "Chile",
                "value": "cl"
            },
            {
                "label": "China",
                "value": "cn"
            },
            {
                "label": "Christmas Island",
                "value": "cx"
            },
            {
                "label": "Cocos (Keeling) Islands (the)",
                "value": "cc"
            },
            {
                "label": "Colombia",
                "value": "co"
            },
            {
                "label": "Comoros (the)",
                "value": "km"
            },
            {
                "label": "Congo (the Democratic Republic of the)",
                "value": "cd"
            },
            {
                "label": "Congo (the)",
                "value": "cg"
            },
            {
                "label": "Cook Islands (the)",
                "value": "ck"
            },
            {
                "label": "Costa Rica",
                "value": "cr"
            },
            {
                "label": "Croatia",
                "value": "hr"
            },
            {
                "label": "Cuba",
                "value": "cu"
            },
            {
                "label": "Curaçao",
                "value": "cw"
            },
            {
                "label": "Cyprus",
                "value": "cy"
            },
            {
                "label": "Czechia",
                "value": "cz"
            },
            {
                "label": "Côte d'Ivoire",
                "value": "ci"
            },
            {
                "label": "Denmark",
                "value": "dk"
            },
            {
                "label": "Djibouti",
                "value": "dj"
            },
            {
                "label": "Dominica",
                "value": "dm"
            },
            {
                "label": "Dominican Republic (the)",
                "value": "do"
            },
            {
                "label": "Ecuador",
                "value": "ec"
            },
            {
                "label": "Egypt",
                "value": "eg"
            },
            {
                "label": "El Salvador",
                "value": "sv"
            },
            {
                "label": "Equatorial Guinea",
                "value": "gq"
            },
            {
                "label": "Eritrea",
                "value": "er"
            },
            {
                "label": "Estonia",
                "value": "ee"
            },
            {
                "label": "Eswatini",
                "value": "sz"
            },
            {
                "label": "Ethiopia",
                "value": "et"
            },
            {
                "label": "Falkland Islands (the) [Malvinas]",
                "value": "fk"
            },
            {
                "label": "Faroe Islands (the)",
                "value": "fo"
            },
            {
                "label": "Fiji",
                "value": "fj"
            },
            {
                "label": "Finland",
                "value": "fi"
            },
            {
                "label": "France",
                "value": "fr"
            },
            {
                "label": "French Guiana",
                "value": "gf"
            },
            {
                "label": "French Polynesia",
                "value": "pf"
            },
            {
                "label": "French Southern Territories (the)",
                "value": "tf"
            },
            {
                "label": "Gabon",
                "value": "ga"
            },
            {
                "label": "Gambia (the)",
                "value": "gm"
            },
            {
                "label": "Georgia",
                "value": "ge"
            },
            {
                "label": "Germany",
                "value": "de"
            },
            {
                "label": "Ghana",
                "value": "gh"
            },
            {
                "label": "Gibraltar",
                "value": "gi"
            },
            {
                "label": "Greece",
                "value": "gr"
            },
            {
                "label": "Greenland",
                "value": "gl"
            },
            {
                "label": "Grenada",
                "value": "gd"
            },
            {
                "label": "Guadeloupe",
                "value": "gp"
            },
            {
                "label": "Guam",
                "value": "gu"
            },
            {
                "label": "Guatemala",
                "value": "gt"
            },
            {
                "label": "Guernsey",
                "value": "gg"
            },
            {
                "label": "Guinea",
                "value": "gn"
            },
            {
                "label": "Guinea-Bissau",
                "value": "gw"
            },
            {
                "label": "Guyana",
                "value": "gy"
            },
            {
                "label": "Haiti",
                "value": "ht"
            },
            {
                "label": "Heard Island and McDonald Islands",
                "value": "hm"
            },
            {
                "label": "Holy See (the)",
                "value": "va"
            },
            {
                "label": "Honduras",
                "value": "hn"
            },
            {
                "label": "Hong Kong",
                "value": "hk"
            },
            {
                "label": "Hungary",
                "value": "hu"
            },
            {
                "label": "Iceland",
                "value": "is"
            },
            {
                "label": "India",
                "value": "in"
            },
            {
                "label": "Indonesia",
                "value": "id"
            },
            {
                "label": "Iran (Islamic Republic of)",
                "value": "ir"
            },
            {
                "label": "Iraq",
                "value": "iq"
            },
            {
                "label": "Ireland",
                "value": "ie"
            },
            {
                "label": "Isle of Man",
                "value": "im"
            },
            {
                "label": "Israel",
                "value": "il"
            },
            {
                "label": "Italy",
                "value": "it"
            },
            {
                "label": "Jamaica",
                "value": "jm"
            },
            {
                "label": "Japan",
                "value": "jp"
            },
            {
                "label": "Jersey",
                "value": "je"
            },
            {
                "label": "Jordan",
                "value": "jo"
            },
            {
                "label": "Kazakhstan",
                "value": "kz"
            },
            {
                "label": "Kenya",
                "value": "ke"
            },
            {
                "label": "Kiribati",
                "value": "ki"
            },
            {
                "label": "Korea (the Democratic People's Republic of)",
                "value": "kp"
            },
            {
                "label": "Korea (the Republic of)",
                "value": "kr"
            },
            {
                "label": "Kuwait",
                "value": "kw"
            },
            {
                "label": "Kyrgyzstan",
                "value": "kg"
            },
            {
                "label": "Lao People's Democratic Republic (the)",
                "value": "la"
            },
            {
                "label": "Latvia",
                "value": "lv"
            },
            {
                "label": "Lebanon",
                "value": "lb"
            },
            {
                "label": "Lesotho",
                "value": "ls"
            },
            {
                "label": "Liberia",
                "value": "lr"
            },
            {
                "label": "Libya",
                "value": "ly"
            },
            {
                "label": "Liechtenstein",
                "value": "li"
            },
            {
                "label": "Lithuania",
                "value": "lt"
            },
            {
                "label": "Luxembourg",
                "value": "lu"
            },
            {
                "label": "Macao",
                "value": "mo"
            },
            {
                "label": "Madagascar",
                "value": "mg"
            },
            {
                "label": "Malawi",
                "value": "mw"
            },
            {
                "label": "Malaysia",
                "value": "my"
            },
            {
                "label": "Maldives",
                "value": "mv"
            },
            {
                "label": "Mali",
                "value": "ml"
            },
            {
                "label": "Malta",
                "value": "mt"
            },
            {
                "label": "Marshall Islands (the)",
                "value": "mh"
            },
            {
                "label": "Martinique",
                "value": "mq"
            },
            {
                "label": "Mauritania",
                "value": "mr"
            },
            {
                "label": "Mauritius",
                "value": "mu"
            },
            {
                "label": "Mayotte",
                "value": "yt"
            },
            {
                "label": "Mexico",
                "value": "mx"
            },
            {
                "label": "Micronesia (Federated States of)",
                "value": "fm"
            },
            {
                "label": "Moldova (the Republic of)",
                "value": "md"
            },
            {
                "label": "Monaco",
                "value": "mc"
            },
            {
                "label": "Mongolia",
                "value": "mn"
            },
            {
                "label": "Montenegro",
                "value": "me"
            },
            {
                "label": "Montserrat",
                "value": "ms"
            },
            {
                "label": "Morocco",
                "value": "ma"
            },
            {
                "label": "Mozambique",
                "value": "mz"
            },
            {
                "label": "Myanmar",
                "value": "mm"
            },
            {
                "label": "Namibia",
                "value": "na"
            },
            {
                "label": "Nauru",
                "value": "nr"
            },
            {
                "label": "Nepal",
                "value": "np"
            },
            {
                "label": "Netherlands (the)",
                "value": "nl"
            },
            {
                "label": "New Caledonia",
                "value": "nc"
            },
            {
                "label": "New Zealand",
                "value": "nz"
            },
            {
                "label": "Nicaragua",
                "value": "ni"
            },
            {
                "label": "Niger (the)",
                "value": "ne"
            },
            {
                "label": "Nigeria",
                "value": "ng"
            },
            {
                "label": "Niue",
                "value": "nu"
            },
            {
                "label": "Norfolk Island",
                "value": "nf"
            },
            {
                "label": "Northern Mariana Islands (the)",
                "value": "mp"
            },
            {
                "label": "Norway",
                "value": "no"
            },
            {
                "label": "Oman",
                "value": "om"
            },
            {
                "label": "Pakistan",
                "value": "pk"
            },
            {
                "label": "Palau",
                "value": "pw"
            },
            {
                "label": "Palestine, State of",
                "value": "ps"
            },
            {
                "label": "Panama",
                "value": "pa"
            },
            {
                "label": "Papua New Guinea",
                "value": "pg"
            },
            {
                "label": "Paraguay",
                "value": "py"
            },
            {
                "label": "Peru",
                "value": "pe"
            },
            {
                "label": "Philippines (the)",
                "value": "ph"
            },
            {
                "label": "Pitcairn",
                "value": "pn"
            },
            {
                "label": "Poland",
                "value": "pl"
            },
            {
                "label": "Portugal",
                "value": "pt"
            },
            {
                "label": "Puerto Rico",
                "value": "pr"
            },
            {
                "label": "Qatar",
                "value": "qa"
            },
            {
                "label": "Republic of North Macedonia",
                "value": "mk"
            },
            {
                "label": "Romania",
                "value": "ro"
            },
            {
                "label": "Russian Federation (the)",
                "value": "ru"
            },
            {
                "label": "Rwanda",
                "value": "rw"
            },
            {
                "label": "Réunion",
                "value": "re"
            },
            {
                "label": "Saint Barthélemy",
                "value": "bl"
            },
            {
                "label": "Saint Helena, Ascension and Tristan da Cunha",
                "value": "sh"
            },
            {
                "label": "Saint Kitts and Nevis",
                "value": "kn"
            },
            {
                "label": "Saint Lucia",
                "value": "lc"
            },
            {
                "label": "Saint Martin (French part)",
                "value": "mf"
            },
            {
                "label": "Saint Pierre and Miquelon",
                "value": "pm"
            },
            {
                "label": "Saint Vincent and the Grenadines",
                "value": "vc"
            },
            {
                "label": "Samoa",
                "value": "ws"
            },
            {
                "label": "San Marino",
                "value": "sm"
            },
            {
                "label": "Sao Tome and Principe",
                "value": "st"
            },
            {
                "label": "Saudi Arabia",
                "value": "sa"
            },
            {
                "label": "Senegal",
                "value": "sn"
            },
            {
                "label": "Serbia",
                "value": "rs"
            },
            {
                "label": "Seychelles",
                "value": "sc"
            },
            {
                "label": "Sierra Leone",
                "value": "sl"
            },
            {
                "label": "Singapore",
                "value": "sg"
            },
            {
                "label": "Sint Maarten (Dutch part)",
                "value": "sx"
            },
            {
                "label": "Slovakia",
                "value": "sk"
            },
            {
                "label": "Slovenia",
                "value": "si"
            },
            {
                "label": "Solomon Islands",
                "value": "sb"
            },
            {
                "label": "Somalia",
                "value": "so"
            },
            {
                "label": "South Africa",
                "value": "za"
            },
            {
                "label": "South Georgia and the South Sandwich Islands",
                "value": "gs"
            },
            {
                "label": "South Sudan",
                "value": "ss"
            },
            {
                "label": "Spain",
                "value": "es"
            },
            {
                "label": "Sri Lanka",
                "value": "lk"
            },
            {
                "label": "Sudan (the)",
                "value": "sd"
            },
            {
                "label": "Suriname",
                "value": "sr"
            },
            {
                "label": "Svalbard and Jan Mayen",
                "value": "sj"
            },
            {
                "label": "Sweden",
                "value": "se"
            },
            {
                "label": "Switzerland",
                "value": "ch"
            },
            {
                "label": "Syrian Arab Republic",
                "value": "sy"
            },
            {
                "label": "Taiwan (Province of China)",
                "value": "tw"
            },
            {
                "label": "Tajikistan",
                "value": "tj"
            },
            {
                "label": "Tanzania, United Republic of",
                "value": "tz"
            },
            {
                "label": "Thailand",
                "value": "th"
            },
            {
                "label": "Timor-Leste",
                "value": "tl"
            },
            {
                "label": "Togo",
                "value": "tg"
            },
            {
                "label": "Tokelau",
                "value": "tk"
            },
            {
                "label": "Tonga",
                "value": "to"
            },
            {
                "label": "Trinidad and Tobago",
                "value": "tt"
            },
            {
                "label": "Tunisia",
                "value": "tn"
            },
            {
                "label": "Turkey",
                "value": "tr"
            },
            {
                "label": "Turkmenistan",
                "value": "tm"
            },
            {
                "label": "Turks and Caicos Islands (the)",
                "value": "tc"
            },
            {
                "label": "Tuvalu",
                "value": "tv"
            },
            {
                "label": "Uganda",
                "value": "ug"
            },
            {
                "label": "Ukraine",
                "value": "ua"
            },
            {
                "label": "United Arab Emirates (the)",
                "value": "ae"
            },
            {
                "label": "United Kingdom of Great Britain and Northern Ireland (the)",
                "value": "gb"
            },
            {
                "label": "United States Minor Outlying Islands (the)",
                "value": "um"
            },
            {
                "label": "United States of America (the)",
                "value": "us"
            },
            {
                "label": "Uruguay",
                "value": "uy"
            },
            {
                "label": "Uzbekistan",
                "value": "uz"
            },
            {
                "label": "Vanuatu",
                "value": "vu"
            },
            {
                "label": "Venezuela (Bolivarian Republic of)",
                "value": "ve"
            },
            {
                "label": "Viet Nam",
                "value": "vn"
            },
            {
                "label": "Virgin Islands (British)",
                "value": "vg"
            },
            {
                "label": "Virgin Islands (U.S.)",
                "value": "vi"
            },
            {
                "label": "Wallis and Futuna",
                "value": "wf"
            },
            {
                "label": "Western Sahara",
                "value": "eh"
            },
            {
                "label": "Yemen",
                "value": "ye"
            },
            {
                "label": "Zambia",
                "value": "zm"
            },
            {
                "label": "Zimbabwe",
                "value": "zw"
            },
            {
                "label": "Åland Islands",
                "value": "ax"
            }
        ],

    propertyConditions: [
        {label: 'Poor Condition', value: 'poorCondition'},
        {label: 'Good Condition', value: 'goodCondition'},
        {label: 'Fair Condition', value: 'fairCondition'},
        {label: 'Excellent Condition', value: 'excellentCondition'}
    ],

    marketValueComparableChoices: [
        {label: 'Comparable 1', value: 'comparable1'},
        {label: 'Comparable 2', value: 'comparable2'},
        {label: 'Comparable 3', value: 'comparable3'},
        {label: 'Average of 1 and 2', value: 'averageOf1And2'},
        {label: 'Average of 1 and 3', value: 'averageOf1And3'},
        {label: 'Average of 2 and 3', value: 'averageOf2And3'},
        {label: 'Average of 1, 2 and 3', value: 'averageOf1A2And3'},
    ],

    propertyTypes: [
        {label: 'Plot & Plan', value: 'plotAndPlan'},
        {label: 'Improving an Existing Property', value: 'improvingAnExistingProperty'},
    ],

    ancillaryOptions: [
        {label: "Boundary Fence", value: "boundaryFence"},
        {label: "Interlocking", value: "interlocking"},
        {label: "Swimming Pool", value: "swimmingPool"},
        {label: "Construction", value: "construction"},
        {label: "Accommodation", value: "accommodation"},
        {label: "Main Building", value: "mainBuilding"}
    ],

    yesNo: [
        {label: "Yes", value: "yes"},
        {label: "No", value: "no"}
    ],

    genders: [
        {label: "Male", value: "male"},
        {label: "Female", value: "female"}
    ],

    maritalStatuses: [
        {label: "Single", value: "single"},
        {label: "Married", value: "married"},
        {label: "Divorced", value: "divorced"},
        {label: "Widowed", value: "widowed"},
        {label: "Common Law", value: "commonLaw"},
        {label: "Any Other", value: "other"},
    ],

    addressTypes: [
        {label: "Mailing", value: "mailing"},
        {label: "Residential", value: "residential"}
    ],

    phoneNumberTypes: [
        {label: "Mobile", value: "mobile"},
        {label: "Home", value: "home"},
        {label: "Work", value: "work"},
        {label: "Fax", value: "fax"}
    ],

    employmentTypes: [
        {label: "Full-time", value: "fullTime"},
        {label: "Part-time", value: "partTime"},
        {label: "Self-employed", value: "selfEmployed"},
        {label: "Contract", value: "contract"},
        {label: "Intern", value: "intern"},
        {label: "Unemployed", value: "unemployed"},
    ],

    parishes: [
        {label: "Clarendon", value: "clarendon"},
        {label: "Hanover", value: "hanover"},
        {label: "Kingston", value: "kingston"},
        {label: "Manchester", value: "manchester"},
        {label: "Portland", value: "portland"},
        {label: "Trelawny", value: "trelawny"},
        {label: "Saint Ann", value: "saintAnn"},
        {label: "Saint Andrew", value: "saintAndrew"},
        {label: "Saint Catherine", value: "saintCatherine"},
        {label: "Saint Elizabeth", value: "saintElizabeth"},
        {label: "Saint James", value: "saintJames"},
        {label: "Saint Mary", value: "saintMary"},
        {label: "Saint Thomas", value: "saintThomas"},
        {label: "Westmoreland", value: "Westmoreland"},
    ],

    homeOwnershipTypes: [
        {label: "Own", value: "own"},
        {label: "Rent", value: "rent"},
        {label: "Lease", value: "lease"},
        {label: "Family Home", value: "familyHome"}
    ],

    relationTypes: [
        {label: "Friend", value: "friend"},
        {label: "Justice of the Peace", value: "justiceOfThePeace"},
        {label: "Medical Practioner", value: "medicalPractitioner"},
        {label: "Minister of Religion", value: "ministerOfReligion"},
        {label: "Police", value: "police"},
        {label: "Staff at Regions", value: "staffAtRegions"},
    ],

    loanPurposes: [
        {label: "Business", value: "business"},
        {label: "Debt Consolidation", value: "debtConsolidation"},
        {label: "Education", value: "education"},
        {label: "Other", value: "other"},
    ],

    loanStatuses: [
        {label: "New", value: "new"},
        {label: "Awaiting Approval", value: "awaitingApproval"},
        {label: "Awaiting Disbursement", value: "awaitingDisbursement"},
        {label: "Cancelled", value: "cancelled"},
    ],

    disbursementMethods: [
        {label: 'Bank Transfer', value:'bankTransfer'},
        {label: 'Cash', value:'cash'},
        {label: 'Cheque', value:'cheque'},
    ],

    bankAccountTypes: [
        {label: 'Saving', value:'saving'},
        {label: 'Chequing', value:'chequing'},
    ],

    reversibleTypes: [
        'payment',
        'penaltyCharged'
    ],

    paymentMethods: [
        {label: 'Cash', value: 'cash'},
        {label: 'Salary Deduction', value: 'salaryDeduction'},
        {label: 'Paymaster', value: 'paymaster'},
        {label: 'Direct Debit (NCB)', value: 'directDebitNcb'},
        {label: 'Direct Debit (Scotia)', value: 'directDebitScotia'},
        {label: 'Direct Debit (First Caribbean)', value: 'directDebitFirstCaribbean'},
        {label: 'Loan Refinance', value: 'loanRefinance'},
    ],

    getList: (listId) => {
        return Lists[listId];
    },

    findByValue: (listId, value) => {
        let listData = Lists.getList(listId) || [];
        for ( let i = 0; i < listData.length; i++ ) {
            if ( listData[i].value === value ) {
                return listData[i].label;
            }
        }
        return '(empty)';
    }

};

export default Lists;