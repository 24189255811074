import {
  SET_CACHE,
  SET_SCHEDULE,
} from '../constants/ActionTypes';

const INIT_STATE = {
  schedule: {},
  cache: {
    recentUpdates: [],
    recentComments: [],
  }
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CACHE: {
      return {
        ...state,
        cache: {...state.cache, ...action.payload}
      };
    }
    case SET_SCHEDULE: {
      return {
        ...state,
        schedule: {...state.schedule, ...action.payload}
      };
    }
    default:
      return state;
  }
}
