import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";


const Clients = ({ match }) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/routes/applications`}/>
            <Route path={`${match.url}/browse/:master`} component={asyncComponent(() => import("./routes/browse"))}/>
            <Route path={`${match.url}/queue/:status`} component={asyncComponent(() => import("./routes/queue/index"))}/>
            <Route path={`${match.url}/queue`} component={asyncComponent(() => import("./routes/queue/index"))}/>
            <Route path={`${match.url}/:loanId/!/:section`} component={asyncComponent(() => import("./routes/view/index"))}/>
        </Switch>
    </div>
);

export default Clients;
