import {
  SET_GLOBAL_STATE
} from '../constants/ActionTypes';
// import data from 'app/routes/contact/data/contactList'

const INIT_STATE = {
  permissions: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_GLOBAL_STATE: {
      return {
        ...state,
        ...action.payload.data
      }
    }
    default:
      return state;
  }
}
