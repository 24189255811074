import React, {Fragment} from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import Http from "./Http";
import {Row, Col} from "reactstrap";
import {ClientAvatar} from "./Template";
import AccessControl from "./AccessControl";

const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(2),
    },
}));

export default function GlobalSearch() {
    const classes = useStyles();
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [searching, setSearching] = React.useState(false);

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                getResults(request, callback);
            }, 200),
        [],
    );

    const getResults = async (requests, callback) => {
        setSearching(true);
        let results = await Http.post({url: '/search', service: 'clients', body: {keywords: requests.input}});
        if ( results.length < 1 ) {
            results = [{label: 'No results yet..'}];
        }
        setSearching(false);
        callback(results.clients.map(client => ({label: client.bio.fullName, value: client._id, loanSummary: client.loanSummary || []})));
    };

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results) => {
            if (active) {
                let newOptions = [];

                if (value) {
                    newOptions = [value];
                }

                if (results) {
                    newOptions = [...newOptions, ...results];
                }

                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    const getResult = result => {
        if ( result.value ) {
            window.location.assign(`/app/clients/${result.value}/!/loans`);
            return;
            if ( result.loanSummary.length === 1 ) {
                let loanId = result.loanSummary[0]._id;
                window.location.assign(`/app/loans/${loanId}/!/summary`);
            } else {
                window.location.assign(`/app/clients/${result.value}/!/summary`);
            }
        }
    };

    return (
        <Autocomplete
            noOptionsText={'No results'}
            id="global-search-field"
            style={{ width: 450 }}
            getOptionLabel={(option) => (typeof option === 'string' ? option : option.label)}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={value}
            onChange={(event, newValue) => {
                getResult(newValue);
                // setOptions(newValue ? [newValue, ...options] : options);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <Row><Col md={10}><TextField placeholder={'Search name, TRN, email, loan ID...'} {...params} variant="outlined" fullWidth className={'global-search-field-input'} /></Col>{searching && <Col md={2} style={{marginTop: '10px'}}><i className={'fa fa-spin fa-spinner'} /></Col>}</Row>
            )}
            renderOption={(client) => {
                return (
                    <Grid container alignItems="center">
                        <Grid item>
                            <AccessControl required={"viewClientAvatars"} fallback={<ClientAvatar
                                src={null} className={"search-result-avatar"} />}>
                                <ClientAvatar
                                    src={client.avatar} className={"search-result-avatar"} />
                            </AccessControl>&nbsp;&nbsp;
                        </Grid>
                        <Grid item xs>
                            <Typography variant="body2" color="textSecondary">
                                <big><strong><a href={`/app/clients/${client.value}/!/summary`} onClick={(e) => {e.preventDefault(); getResult(client)}} className={'no-decoration hover-blue'}>{client.label}</a></strong></big><br />
                                {client.loanSummary.length < 1 && <>No loans found</>}
                                {client.loanSummary.length > 0 &&
                                <>
                                    {client.loanSummary.map((loan, key) => (
                                        <Fragment key={key}>
                                            <a title={(loan.status || '').toFriendlyCase()} href={`/app/loans/${loan._id}/!/summary`} className={'no-decoration hover-blue'}><i className={`fa fa-circle color-chip-${(loan.status || '').toLowerCase()}`} />&nbsp;{loan.loanNumber}</a> &nbsp;&nbsp;&nbsp;
                                        </Fragment>
                                    ))}
                                </>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                );
            }}
        />
    );
}
