import axios from 'axios';

const Elements = {
    "ssn": {"name": "ssn", "type": "text", "label": "Social Security Number", "required": false, "unique": ["Member"], "mask-OMITTED": "ssn"},
    "medicaidNumber": {"name": "medicaidNumber", "type": "text", "label": "Medicaid Number", "required": true, "mask": "medicaid"},
    "firstName": {"name": "firstName", "type": "text", "label": "First Name", "required": true},
    "middleName": {"name": "middleName", "type": "text", "label": "Middle Name"},
    "lastName": {"name": "lastName", "type": "text", "label": "Last Name", "required": true},
    "phoneNumber": {"name": "phoneNumber", "label": "Phone Number", "type": "text", "required": true, "mask": "phoneNumber"},
    "faxNumber": {"name": "faxNumber", "label": "Fax Number", "type": "text", "required": true, "mask": "phoneNumber"},
    "email": {"name": "email", "type": "text", "label": "Email Address", "required": true, "mask": "email"},
    "dateOfBirth": {"name": "dateOfBirth", "type": "date", "label": "Date of Birth", "required": true},
    "gender": {"name": "gender", "type": "dropdown", "label": "Gender", "required": true, "list": "genders"},
    "language": {"name": "language", "type": "dropdown", "label": "Language", "required": true, "list": "languages"},
    "ethnicity": {"name": "ethnicity", "type": "dropdown", "label": "Ethnicity", "list": "ethnicity"},
    "salutation": {"name": "salutation", "type": "dropdown", "label": "Salutation", "list": "salutations"},
    "maritalStatus": {"name": "maritalStatus", "type": "dropdown", "label": "Marital Status", "list": "maritalStatuses"},
    "addressLine1": {"name": "addressLine1", "type": "text", "label": "Apartment/Suite Name & Unit No.", "required": false},
    "addressLine2": {"name": "addressLine2", "type": "text", "label": "Street Number & Name", "required": true},
    "county": {"name": "county", "type": "text", "label": "County", "required": true},
    "city": {"name": "city", "type": "text", "label": "City", "required": true},
    "state": {"name": "state", "type": "dropdown", "label": "State", "required": true, "list": "states"},
    "homeStatus": {"name": "homeStatus", "type": "dropdown", "label": "Living Arrangement", "required": true, "list": "homeStatuses"},
    "zipCode": {"name": "zipCode", "type": "text", "label": "Zip Code", "required": true, "mask": "zipCode"},
    "fullName": {"name": "fullName", "type": "text", "label": "Full Name", "required": true},
    "name": {"name": "name", "type": "text", "label": "Name", "required": true},
    "doctorType": {"name": "doctorType", "type": "dropdown", "label": "Doctor Type", "required": true, "list": "doctorTypes"},
    "contactType": {"name": "contactType", "type": "dropdown", "label": "Contact Type", "required": true, "list": "contactTypes"},
    "donut0": {"name": "donut0", "type": "password", "label": "Current Password", "required": false},
    "donut1": {"name": "donut1", "type": "password", "label": "Password", "required": true},
    "donut2": {"name": "donut2", "type": "password", "label": "Password (confirm)", "required": true},
};

export default Elements;