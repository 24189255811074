import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";


const Clients = ({ match }) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/browse`}/>
            <Route path={`${match.url}/browse`} component={asyncComponent(() => import("./routes/view/index"))}/>
            <Route path={`${match.url}/:clientId/!/:section`} component={asyncComponent(() => import("./routes/view/index"))}/>
        </Switch>
    </div>
);

export default Clients;
