import * as ActionTypes from '../constants/ActionTypes';

export const setCache = (data) => {
  return {
    type: ActionTypes.SET_CACHE,
    payload: data
  };
};

export const setSchedule = (data) => {
  return {
    type: ActionTypes.SET_SCHEDULE,
    payload: data
  };
};
