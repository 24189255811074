import React, {useEffect, useReducer, useState} from 'react';
import {Link} from 'react-router-dom'
import {connect, useDispatch, useSelector} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import IntlMessages from 'util/IntlMessages';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Modal, ModalBody, ModalHeader} from 'reactstrap';
import {
  hideMessage,
  showAuthLoader,
  userFacebookSignIn,
  userGithubSignIn,
  userGoogleSignIn,
  userSignIn,
  userResetPassword,
  userCompleteResetPassword,
  userTwitterSignIn
} from 'actions/Auth';
import {Alert} from "@material-ui/lab";
import {Checkbox, Input} from "@material-ui/core";

const SignIn = (props) => {

  const {auth} = props;
  const [email, setEmail] = useState('');
  const [resetType, setResetType] = useState('email');
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordTest, setPasswordTest] = useState(false);
  const [passwordConfirmed, setPasswordConfirmed] = useState(false);
  const [resetCode, setResetCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const dispatch = useDispatch();
  const {loader, alertMessage, showMessage, authUser} = useSelector(({auth}) => auth);

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => {
        dispatch(hideMessage());
      }, 100);
    }
    if (authUser !== null) {
      props.history.push('/');
    }
  }, [showMessage, authUser, props.history, dispatch]);

  const [resetMode, setResetMode] = useState(false);

  const testPassword = (value) => {
    setConfirmPassword(value);
    if ( newPassword === value && newPassword.length > 1 ) {
      setPasswordConfirmed(true);
    } else {
      setPasswordConfirmed(false);
    }
  };

  const doLogin = () => {
    if ( email && password ) {
      dispatch(showAuthLoader());
      dispatch(userSignIn({email, password}));
    }
  };

  const loginEnter = e => {
    if ( e.keyCode === 32 || e.key === 'Enter' ) {
      doLogin();
    }
  };

  const passwordTestWindow = <>
    <Modal isOpen={passwordTest && !props.auth.resetTokenId} toggle={() => setPasswordTest(false)}>
      <ModalHeader toggle={() => setPasswordTest(false)}>
        New Password Test
      </ModalHeader>
      <ModalBody>
        <h1>Here is your first test.</h1>
        <h3>Do you remember the password you just entered?</h3>
        {confirmPassword && !passwordConfirmed &&
        <>
          <Alert severity={"error"}>
            Password test failed! {!showPassword && <><span className={"clickable link-text"} onClick={() => setShowPassword(true)}>[Show me the password]</span></>}
            {showPassword && <>You entered: <strong>{newPassword}</strong> &nbsp; <span className={"clickable link-text"} onClick={() => setShowPassword(false)}>[Hide]</span></>}
          </Alert>
        </>
        }
        {confirmPassword && passwordConfirmed &&
        <>
          <Alert severity={"success"}>
            ✨✨ Passwords matched! ✨✨
          </Alert>
        </>
        }
        <fieldset>

          <TextField
              type={"password"}
              label={"Re-enter Password"}
              fullWidth
              onChange={(event) => testPassword(event.target.value)}
              defaultValue={confirmPassword}
              margin="normal"
              className="mt-1 my-sm-3"
          />

          <br />

          <TextField
              label={"Reset Code (check your " + (resetType === 'phoneNumber' ? 'phone' : 'email') + ")"}
              fullWidth
              onChange={(event) => setResetCode(event.target.value)}
              defaultValue={resetCode}
              margin="normal"
              className="mt-1 my-sm-3"
          />

          <br />

          {passwordConfirmed && <Button onClick={() => {
            dispatch(showAuthLoader());
            dispatch(userCompleteResetPassword({email, resetCode, newPassword}));
          }} variant="contained" color="primary">
            Complete Password Reset
          </Button>}

          {auth.tokenId &&
          <>
            &nbsp;&nbsp;
            <Button onClick={() => {
              dispatch(showAuthLoader());
              dispatch(userResetPassword({email, phoneNumber, resetType}));
            }} color="secondary">
              Resend Code
            </Button>
          </>
          }

        </fieldset>

      </ModalBody>
    </Modal>
  </>;

  const isInactiveRedirect = window.location.href.indexOf('cm=inactivity') > -1

  return (
      <div
          className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
        {passwordTestWindow}
        <div className="app-login-main-content">

          <div className="app-logo-content d-flex align-items-center justify-content-center">
            <Link className="logo-lg" to="/" title="Regions Financial Services">
              <img src={require("assets/images/logo.png")} alt="Regions Financial Services" title="Regions Financial Services"/>
            </Link>
          </div>

          <div className="app-login-content">


            {isInactiveRedirect &&
            <>
              <div className={''}>
                <Alert severity={'warning'}>
                  Your session was ended for security reasons due to inactivity.
                </Alert>
              </div>
              <hr />
            </>
            }

            <div className="app-login-header mb-4">
              {!resetMode &&
              <h1>
                {isInactiveRedirect && <>Please sign in again...</>}
                {!isInactiveRedirect && <>Back-end Log In</>}
              </h1>
              }
              {resetMode && <h1 className={"text-danger"}>Reset Password</h1>}
            </div>

            <div className="app-login-form">
              {!resetMode && <form>
                <fieldset>
                  <TextField
                      label={<IntlMessages id="appModule.email"/>}
                      fullWidth
                      onChange={(event) => setEmail(event.target.value)}
                      onKeyDown={(e) => loginEnter(e)}
                      defaultValue={email}
                      margin="normal"
                      className="mt-1 my-sm-3"
                  />
                  <TextField
                      type="password"
                      label={<IntlMessages id="appModule.password"/>}
                      fullWidth
                      onChange={(event) => setPassword(event.target.value)}
                      defaultValue={password}
                      onKeyDown={(e) => loginEnter(e)}
                      margin="normal"
                      className="mt-1 my-sm-3"
                  />

                  <div className="mb-3 d-flex align-items-center justify-content-between">
                    <Button onClick={() => doLogin()} variant="contained" color="primary">
                      <IntlMessages id="appModule.signIn"/>
                    </Button>
                    &nbsp;&nbsp;
                    <Button onClick={() => setResetMode(true)}>
                      Reset Password
                    </Button>

                  </div>

                </fieldset>
              </form>}
              {resetMode && <form>
                {!auth.resetTokenId &&
                <fieldset>
                  {!auth.tokenId &&
                  <>
                    <TextField
                        label={<IntlMessages id="appModule.email"/>}
                        fullWidth
                        onChange={(event) => setEmail(event.target.value)}
                        defaultValue={email}
                        margin="normal"
                        className="mt-1 my-sm-3"
                    />
                    <br />
                    <Checkbox
                        onChange={() => setResetType(resetType === 'email' ? 'phoneNumber' : 'email')}
                        checked={resetType === 'phoneNumber'}
                    />Use phone number to receive code
                    <br />
                    {resetType === 'phoneNumber' &&
                    <TextField
                        label={"Phone Number"}
                        fullWidth
                        onChange={(event) => setPhoneNumber(event.target.value)}
                        defaultValue={phoneNumber}
                        margin="normal"
                        className="mt-1 my-sm-3"
                    />}

                    <br/>

                    <Button onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userResetPassword({email, phoneNumber, resetType}));
                    }} variant="contained" color="primary">
                      Reset
                    </Button>
                  </>
                  }

                  {auth.tokenId &&
                  <>
                    <TextField
                        label={"Reset Code (check your " + (resetType === 'phoneNumber' ? 'phone' : 'email') + ")"}
                        fullWidth
                        onChange={(event) => setResetCode(event.target.value)}
                        defaultValue={resetCode}
                        margin="normal"
                        className="mt-1 my-sm-3"
                    />
                    <TextField
                        type="password"
                        label={"Your New Password"}
                        fullWidth
                        onChange={(event) => setNewPassword(event.target.value)}
                        defaultValue={newPassword}
                        margin="normal"
                        className="mt-1 my-sm-3"
                    />
                    <small>Choose something you can remember this time :)</small>
                    <br /><br />
                    <Button onClick={() => setPasswordTest(true)} variant="contained" color="primary">
                      Next
                    </Button>
                    &nbsp;&nbsp;
                    <Button onClick={() => {
                      dispatch(showAuthLoader());
                      dispatch(userResetPassword({email, phoneNumber}));
                    }} color="secondary">
                      Resend Code
                    </Button>
                  </>
                  }

                  &nbsp;&nbsp;
                  <Button onClick={() => setResetMode(false)}>
                    <i className={"fa fa-chevron-left"}></i>&nbsp; Back to Log-in
                  </Button>

                </fieldset>
                }
                {auth.resetTokenId &&
                <>
                  <Alert severity={"info"}>
                    Congratulations! Your password has been successfully reset.
                  </Alert>
                  <br /><br />
                  <Button onClick={() =>window.location.reload()} variant="contained" color="primary">
                    Sign in with New password
                  </Button>
                </>
                }
              </form>}
            </div>
          </div>

        </div>
        {
          loader &&
          <div className="loader-view">
            <CircularProgress/>
          </div>
        }
        {showMessage && NotificationManager.error(alertMessage)}
        <NotificationContainer/>
      </div>
  );
};


SignIn.propTypes = {
};

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SignIn);