import * as ActionTypes from '../constants/ActionTypes';

export const setCache = (data) => {
  return {
    type: ActionTypes.SET_CACHE,
    payload: data
  };
};

export const setBio = (data) => {
  return {
    type: ActionTypes.SET_BIO,
    payload: data
  };
};

export const setContact = (data) => {
  return {
    type: ActionTypes.SET_CONTACT,
    payload: data
  };
};

export const setEmployment = (data) => {
  return {
    type: ActionTypes.SET_EMPLOYMENT,
    payload: data
  };
};
