import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import asyncComponent from "../../../util/asyncComponent";


const Reports = ({ match }) => (
    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/browse`}/>
            <Route path={`${match.url}/:reportId/designer`} component={asyncComponent(() => import("./routes/designer"))}/>
            <Route path={`${match.url}/browse`} component={asyncComponent(() => import("./routes/browse"))}/>
        </Switch>
    </div>
);

export default Reports;
