import React, {useState} from "react";
import { Link, withRouter } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { COLLAPSED_DRAWER, FIXED_DRAWER } from "constants/ActionTypes";
import SearchBox from "components/SearchBox";
import MailNotification from "../../../../components/MailNotification";
import AppNotification from "../../../../components/AppNotification";
import CardHeader from "components/dashboard/Common/CardHeader/index";
import { switchLanguage, toggleCollapsedNav } from "actions/Setting";
import IntlMessages from "util/IntlMessages";
import LanguageSwitcher from "components/LanguageSwitcher/index";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import Avatar from "@material-ui/core/Avatar";
import {PageTitle} from "../../../../util/Template";
import Messenger from '../../../../util/Messenger';

const Index =(props)=> {

  const st = {};
  const dispatch = useDispatch();
  const { drawerType, locale } = useSelector(({settings}) => settings);
  const [langSwitcher,setLangSwitcher]=useState(false);
  const [mailNotification,setMailNotification]=useState(false);
  const [appNotification,setAppNotification]=useState(false);
  const [searchBox,setSearchBox]=useState(false);
  const [apps,setApps]=useState(false);
  const [userInfo,setUserInfo]=useState(false);
  const [searchText,setSearchText]=useState('');
  const [anchorEl,setAnchorEl]=useState(undefined);

  const [anchorE2, setAnchorE2] = useState(null);
  const [open, setOpen] = useState(false);

  const onAppNotificationSelect = () => {
    setAppNotification(!appNotification)
  };

  const onMailNotificationSelect = () => {
    setMailNotification(!mailNotification)
  };
  const onLangSwitcherSelect = (event) => {
    setLangSwitcher(!langSwitcher);
    setAnchorEl(event.target.value);
  };

  const onSearchBoxSelect = () => {
    setSearchBox(!searchBox)
  };
  const onAppsSelect = () => {
    setApps(!apps)
  };
  const onUserInfoSelect = () => {
    setUserInfo(!userInfo);
  };

  const handleRequestClose = () => {
    setSearchBox(false);
    setLangSwitcher(false);
    setUserInfo(false);
    setMailNotification(false);
    setSearchBox(false);
    setApps(false);
    setOpen(false);
  };

  const onToggleCollapsedNav = (e) => {
    const val = !props.navCollapsed;
    dispatch(toggleCollapsedNav(val));
  };

  const handleClick = event => {
    setOpen(true);
    setAnchorE2(event.currentTarget);
  };

  const Apps = () => {
    return (
      <ul className="jr-list jr-list-half">
        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/calendar/basic">
            <i className="zmdi zmdi-calendar zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="sidebar.calendar.basic"/></span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/to-do">
            <i className="zmdi zmdi-check-square zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="sidebar.appModule.toDo"/></span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/mail">
            <i className="zmdi zmdi-email zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="sidebar.appModule.mail"/></span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/chat">
            <i className="zmdi zmdi-comment zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="sidebar.appModule.chat"/></span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/app/contact">
            <i className="zmdi zmdi-account-box zmdi-hc-fw"/>
            <span className="jr-list-text"><IntlMessages id="sidebar.appModule.contact"/></span>
          </Link>
        </li>

        <li className="jr-list-item">
          <Link className="jr-list-link" to="/">
            <i className="zmdi zmdi-plus-circle-o zmdi-hc-fw"/>
            <span className="jr-list-text">Add New</span>
          </Link>
        </li>
      </ul>);
  };


  const updateSearchText =(evt)=> {
    setSearchText(evt.target.value);
  };

  const onSwitchLanguage = (lang) => {
    dispatch(switchLanguage(lang))
  };

    const drawerStyle = drawerType.includes(FIXED_DRAWER) ? "d-block d-xl-none" : drawerType.includes(COLLAPSED_DRAWER) ? "d-block" : "d-none";

    return (
      <AppBar className="app-main-header">
        <Toolbar className="app-toolbar" disableGutters={false}>

          <IconButton className={`jr-menu-icon mr-3 ${drawerStyle}`} aria-label="Menu"
                      onClick={onToggleCollapsedNav}>
            <span className="menu-icon"/>
          </IconButton>

          <PageTitle />


          <ul className="header-notifications list-inline ml-auto">
            {st.DEMO &&
            <li className="list-inline-item">
              <Dropdown
                  className="quick-menu app-notification"
                  isOpen={apps}
                  toggle={onAppsSelect}>

                <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">
                  <span className="app-notification-menu">
                    <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg"/>
                    <span>Apps</span>
                  </span>
                </DropdownToggle>

                <DropdownMenu>
                  {Apps()}
                </DropdownMenu>
              </Dropdown>
            </li>
            }
            {st.DEMO &&
            <li className="d-inline-block d-lg-none list-inline-item">
              <Dropdown
                  className="quick-menu nav-searchbox"
                  isOpen={searchBox}
                  toggle={onSearchBoxSelect}>

                <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-search zmdi-hc-fw"/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="p-0">
                  <SearchBox styleName="search-dropdown" placeholder=""
                             onChange={updateSearchText}
                             value={searchText}/>
                </DropdownMenu>
              </Dropdown>
            </li>
            }
            {st.DEMO &&
            <li className="list-inline-item hidden">
              <Dropdown
                  className="quick-menu"
                  isOpen={langSwitcher}
                  toggle={onLangSwitcherSelect}>

                <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">
                  <IconButton className="icon-btn">
                    <i className={`flag flag-24 flag-${locale.icon}`}/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right className="w-50">
                  <LanguageSwitcher switchLanguage={onSwitchLanguage}
                                    handleRequestClose={handleRequestClose}/>
                </DropdownMenu>
              </Dropdown>
            </li>
            }
            <li className="list-inline-item app-tour">
              <SearchBox styleName="d-none d-lg-block" placeholder=""
                         onChange={updateSearchText}
                         value={searchText}/>
            </li>
            <li className="list-inline-item app-tour">
              <Dropdown
                className="quick-menu"
                isOpen={appNotification}
                toggle={onAppNotificationSelect}>

                <DropdownToggle
                  className="d-inline-block"
                  tag="span"
                  data-toggle="dropdown">
                  <IconButton className="icon-btn black">
                    <i className="zmdi zmdi-notifications-none icon-alert"/>
                  </IconButton>
                </DropdownToggle>

                <DropdownMenu right>
                  <CardHeader styleName="align-items-center"
                              heading={<IntlMessages id="appNotification.title"/>}/>
                  <AppNotification/>
                </DropdownMenu>
              </Dropdown>
            </li>
            <li className="list-inline-item app-tour">
              <a href="#!" onClick={handleClick} className={"black text-150"}>
                <i className="zmdi zmdi-account-circle zmdi-hc-fw mr-2"/>
              </a>
              <Menu className="user-info"
                    id="simple-menu"
                    anchorEl={anchorE2}
                    open={open}
                    onClose={handleRequestClose}
                    PaperProps={{
                      style: {
                        minWidth: 120,
                        paddingTop: 0,
                        paddingBottom: 0
                      }
                    }}
              >
                <MenuItem onClick={handleRequestClose}>
                  <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
                  Manage Account
                </MenuItem>
                <MenuItem onClick={() => {
                  handleRequestClose();
                }}>
                  <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>
                  Sign Out
                </MenuItem>
              </Menu>
            </li>
            {st.DEMO &&
            <li className="list-inline-item mail-tour">
              <Dropdown
                  className="quick-menu"
                  isOpen={mailNotification}
                  toggle={onMailNotificationSelect}
              >
                <DropdownToggle
                    className="d-inline-block"
                    tag="span"
                    data-toggle="dropdown">

                  <IconButton className="icon-btn">
                    <i className="zmdi zmdi-comment-alt-text zmdi-hc-fw"/>
                  </IconButton>
                </DropdownToggle>


                <DropdownMenu right>
                  <CardHeader styleName="align-items-center"
                              heading={<IntlMessages id="mailNotification.title"/>}/>
                  <MailNotification/>
                </DropdownMenu>
              </Dropdown>
            </li>
            }

          </ul>

          <div className="ellipse-shape"/>
        </Toolbar>
      </AppBar>
    );
  };


export default withRouter(Index);
